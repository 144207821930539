<template>
  <div class="h-full">
    <Scrollbar>
      <Table :auto-loading="true" :options="handleOptions" :loadData="handleLoadData" ref="table" :handleInfo="{label:'操作',width:220}">
        <template #search>
          <div class="mb-2">
            <el-button-group>
              <el-button :type="formSearch['status']==null?'primary':''" @click="changeBtn()" >全部</el-button>
              <el-button :type="formSearch['status']==-1?'primary':''" @click="changeBtn(-1)">未审核</el-button>
              <el-button :type="formSearch['status']==1?'primary':''" @click="changeBtn(1)" >已审核</el-button>
            </el-button-group>
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handlePhoto(row)">查看照片</el-button>
          <el-button type="text" @click="handleUpdate(row)">编辑</el-button>
          <el-button v-if="row['status']== '未审核'" type="text"  @click="handleAuditing(row,true)">通过</el-button>
          <el-button v-if="row['status']== '未审核'" type="text" class="text-red-600" @click="handleAuditing(row,false)">驳回</el-button>
          <el-button type="text" class="text-red-600" @click="handleRemove(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
    <el-dialog top="7vh" title="查看照片" :visible.sync="photoVisible">
      <el-carousel height="480px" :autoplay="false">
        <el-carousel-item v-for="item in photoList" :key="item">
          <div style="text-align: center;height: 100%;display: flex;align-items: center;justify-content: center">
            <img style="height: 100%" :src="item"/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>

    <Dialog v-loading="loading" :visible.sync="updateVisible" width="700px" height="600px" title="更新获奖信息">
      <el-form class="scroll" label-width="100px" :model="updateData" :rules="rules" ref="updateData">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="学号：">
              {{ updateData.student_number }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名：">
              {{ updateData.student_real_name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别：">
              {{ updateData.student_sex }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="班级：">
              {{ updateData.class_name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="科目：">
              {{ updateData.subject_name }}
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 可修改内容  -->
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="获奖名称：" prop="name">
              <el-input v-model="updateData.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="获奖级别：" prop="level_id">
              <el-select v-model="updateData.level_id">
                <el-option v-for="(item,index) in actionSheetList" :value="item.value" :label="item.label" :key="index">{{ item.label }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="颁奖单位：" prop="unit">
              <el-input v-model="updateData.unit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="获奖等第：" prop="rank">
              <el-input v-model="updateData.rank"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="颁奖内容：" prop="content">
              <el-input type="textarea" v-model="updateData.content"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="获奖照片：" prop="photos">
              <el-upload
                action="#"
                list-type="picture-card"
                :file-list="fileList"
                :http-request="onUploadPicture"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemovePhoto(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="获奖时间：" prop="time">
              <el-date-picker
                v-model="updateData.time"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择获奖时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核状态：">
              <el-radio-group v-model="updateData.status_id">
                <el-radio-button :label="1">通过</el-radio-button>
                <el-radio-button :label="0">驳回</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="updateData.status_id === 0">
            <el-form-item label="驳回理由：" prop="reason">
              <el-input type="textarea" v-model="updateData.reason" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button type="primary" size="mini" @click="handleConfirm">确定</el-button>
        <el-button size="mini" @click="updateVisible = false">取消</el-button>
      </template>
    </Dialog>

    <Dialog :is-footer="false" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </Dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "medal",
  data() {
    return {
      activeBtn:null,
      options: {
        header: [],
        search: []
      },
      photoList:[],
      photoVisible:false,
      //搜索表单结构
      formSearch: {
          status:null
      },


      loading:false,
      actionSheetList:[],
      updateVisible:false,
      updateData:{},
      rules:{
        name:[{
          required: true, message: '请输入获奖名称', trigger: 'blur'
        }],
        level_id:[{
          required: true, message: '请选择获奖级别', trigger: 'blur'
        }],
        unit:[{
          required: true, message: '请输入颁奖单位', trigger: 'blur'
        }],
        rank:[{
          required: true, message: '请输入获奖等第', trigger: 'blur'
        }],
        content:[{
          required: true, message: '请输入颁奖内容', trigger: 'blur'
        }],
        time:[{
          required: true, message: '请选择获奖时间', trigger: 'blur'
        }],
        photos:[{
          required: true,
          validator:(rule, value, callback)=>{
            if(value?.length){
              callback()
            }else{
              callback(new Error('请上传获奖照片！'))
            }
          }
        }]
      },
      fileList:[],
      dialogVisible:false,
      dialogImageUrl:'',
    };
  },
  watch:{
    updateData:{
      deep:true,
      immediate:true,
      handler(val){
        if(val.status_id === 0){
          this.$set(this.rules,'reason',[{required: true, message: '请输入驳回原因', trigger: 'blur'}])
        }else{
          delete this.rules['reason']
        }
      }
    }
  },
  async mounted() {
    await this.handleLoadLevel()
  },
  methods: {
    async handleLoadLevel(){
      await this.$cloud.get('/setting/honor/level').then(res=>{
        this.actionSheetList = []
        for (let i in res) {
          this.actionSheetList.push({
            value: i,
            label: res[i]
          })
        }
      })
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/web/award").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("honor/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    handleAuditing(item,type) {
      if(type){
        this.$confirm(`审核通过?`, '提示', {
          confirmButtonText: '通过',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.onAudit(item.id,1,'审核')
        })
      }else{
        this.$prompt('请输入驳回理由', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValidator: (value) => {
            if(!value) {
              return '驳回理由不能为空！';
            }
          },
          inputErrorMessage: '驳回理由?',
        }).then(({ value }) => {
          this.onAudit(item.id,0,'驳回',value)
        }).catch(() => {})
      }

    },
    handleRemove(item) {
      this.$confirm(`删除获奖内容?`, '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("honor/remove", {
          id:item.id
        }).then(res => {
          this.$message.success(`删除成功！`)
          this.handleRefresh();
        })
      })

    },
    handlePhoto(row) {
      console.log('查看照片',row)
      this.$cloud.get("honor/detail",{id:row.id}).then(res => {
        console.log("详情",res)
        this.photoList = res.photos
        this.photoVisible = true
      })
    },
    onAudit(id,status,message,reason = null){
      this.$cloud.post("honor/audit", {
        id,status,reason
      }).then(res => {
        this.$message.success(`${message}成功！`)
        this.handleRefresh();
      })
    },


    //照片上传
    onUploadPicture(file) {
      let params = {
        file: file.file,
      };
      this.loading = true;
      this.$cloud
        .upload("files/upload", params)
        .then((res) => {
          this.loading = false;
          this.fileList.push({
            name:file.file['name'],
            url:res.url
          })
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleRemovePhoto(file){
      this.fileList = this.fileList.filter(item=>item.name !== file.name)
    },

    // 打开编辑对话框
    handleUpdate(row){
      this.loading = true
      this.updateData = row
      this.$set(this.updateData,'level_id',String(this.updateData.level_id))
      this.$cloud.get("honor/detail",{id:row.id}).then(res => {
        this.loading = false
        this.fileList = [];
        if(res.photos?.length){
          res.photos.map((url)=>{
            let name = url.split('/')[url.split('/').length - 1]
            this.fileList.push({
              name,
              url,
            })
          })
        }
      }).catch(()=>{
        this.loading = false;
      })
      this.updateVisible = true;
    },
     //  保存修改
    handleConfirm(){
      // 图片处理
      let photos = []
      if(this.fileList?.length){
        photos = this.fileList.map(item=>item.url);
      }
      this.$set(this.updateData,'photos',photos);
      this.$refs.updateData.validate((valid)=>{
        if(valid){
          this.$confirm(`确定更新获奖信息吗?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let { id, name, level_id, time , unit, rank, content, status_id, reason,photos} = this.updateData;
            if(status_id !== 0){
              reason = '';
            }
            this.loading = true
            this.$cloud.post('/honor/edit',{
              id, name, level_id, time , unit, rank, content, status:status_id, reason,photos
            }).then(()=>{
              this.loading= false
              this.updateVisible = false;
              this.$message.success(`更新成功！`)
              this.handleRefresh();
            }).catch(()=>{
              this.loading = false
            })
          })
        }
      })
      console.log('data:',this.updateData)
    },

    changeBtn(val){
      this.formSearch.status = val
      this.handleRefresh();
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss">
.scroll{
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>
